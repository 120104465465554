@import 'node_modules/bootstrap/scss/functions';
@import 'node_modules/bootstrap/scss/bootstrap';
@import 'src/variables';

@font-face {
  font-family: "Archivo";
  src: url("assets/font/Archivo.ttf") format("opentype");
}

@font-face {
  font-family: "Archivo Italic";
  src: url("assets/font/Archivo-Italic.ttf") format("opentype");
}


html, body {
  height: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
}

main {
  overflow-x: clip;
}

* {
  font-family: $font-regular;
}

.justify-self-end {
  justify-self: end;
}

.siel-logo {
  height: auto;
  width: 100%;
}

.w-40 {
  width: 40%;
}

.w-60 {
  width: 60%;
}

header {
  .navbar-nav {
    .nav-item {
      > .dropdown-menu {
        left: 50%;
        transform: translateX(-50%);
      }
      .dropdown-menu {
        border: none;
        min-width: 240px;
        border-radius: 6px;
        margin-top: 0;
        margin-left: 0;
        .dropdown-item {
          font-size: 14px;
          font-family: $font-regular;
          font-weight: $font-weight-semibold;
          padding: 8px 20px;
          color: $gray-700;
          margin: 0;
          .badge {
            position: relative;
            top: -1px;
          }
          .mt-2 {
            margin-top: 0.40rem !important;
          }
          &:hover {
            color: $success;
            background-color: transparent;
            svg path {
              fill: $success;
            }
          }
          &:focus {
            background-color: transparent;
          }
        }
      }
      &.horizontal-menu {
        .dropdown-menu {
          padding: 13px 15px;
         > li {
            list-style: none;
            small {
              padding: 6px 20px;
              color: $gray-600;
            }
            ul {
              padding-left: 0;
              li {
                list-style: none;
                .dropdown-item {
                  padding: 6px 20px;
                }
              }
            }
          }
          &.show {
            display: flex;
            flex-wrap: wrap;
            padding: 13px 15px;
          }
        }
      }
    }
    .btn {
      border-radius: 12px;
      font-weight: $font-weight-bold;
      font-size: 18px;
      padding: 19px 42px;
      border-width: 0px;
      color: #20023D;
      background-color: $secondary;
      transition: all 0.5s ease-in-out;
    }
    .btn:active {
      background-color: $primary !important;
      color: $white !important;
    }
    .btn:hover {
      background-color: $primary;
      color: $white;
    }
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0;
  }
  .nav-link {
    color: $black-600;
    font-family: $font-regular;
    font-weight: $font-weight-semibold;
    font-size: 18px;
    &:hover {
      color: $primary;
    }
  }
}

.banner > .container {
  .btn {
    border-radius: 12px;
    font-weight: $font-weight-bold;
    font-size: 18px;
    padding: 19px 42px;
    border-width: 0px;
    color: #20023D;
    background-color: $secondary;
    transition: all 0.5s ease-in-out;
  }
  .btn:hover {
    background-color: $primary;
    color: $white;
  }
  .btn:active {
    background-color: $primary !important;
    color: $white !important;
  }
}

.text-white {
  color: #ffffff;
}

.template-text-primary {
  color: $secondary;
}


.social-icon {
  max-width: 30px;
  height: auto;
}

.social-icon img {
  width: 100%;
  height: auto;
}

.container {
  .section-title {
    font-weight: $font-weight-light;
    font-family: $font-italic;
    font-size: clamp(3.529rem, 4vw, 7.143rem);
    line-height: clamp(3.529rem, 6vw, 7.786rem);
    letter-spacing: 0px;
    color: $primary;
    z-index: 1;
  }
  .section-title-2 {
    font-weight: $font-weight-light;
    font-family: $font-italic;
    font-size: clamp(3.250rem, 5vw, 7.143rem);
    letter-spacing: 0px;
    color: $secondary;
    z-index: 1;
    margin: 0;
  }
  .section-subtitle {
    font-weight: $font-weight-light;
    font-family: $font-italic;
    font-size: clamp(1.814rem, 3vw, 2.286rem);
    letter-spacing: 0px;
    color: $secondary;
    z-index: 1;
    margin: 0;
  }

  .section-subtitle-2 {
    font: normal normal $font-weight-semibold clamp(1.35rem, 2.85vw, 3.35rem) / clamp(1.85rem, 4.35vw, 3.85rem) $font-regular;
    letter-spacing: 0px;
    color: $white;
  }

  .team-read-more {
    .btn {
      border-radius: 12px;
      font-weight: $font-weight-bold;
      font-size: 18px;
      padding: 19px 42px;
      border-width: 0px;
      color: #20023D;
      background-color: $secondary;
      transition: all 0.5s ease-in-out;
    }
    .btn:hover {
      background-color: $primary;
      color: $white;
    }
    .btn:active {
      background-color: $primary !important;
      color: $white !important;
    }
  }
}

.primary-section {
  margin-top: 164px;
}

//xs
@media (max-width: 575.98px) {
  .main-template .container {
    max-width: 90%;
  }
}

//sm
@media (min-width: 576px) and (max-width: 767.98px) {
  .main-template .container {
    max-width: 90%;
  }
}

//md
@media (min-width: 768px) and (max-width: 991.98px) {
  .main-template .container {
    max-width: 85%;
  }
}

//lg
@media (min-width: 992px) and (max-width: 1199.98px) {
  .main-template .container {
    max-width: 85%;
  }
}

//xl
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .main-template .container {
    max-width: 85%;
  }
}
//xxl
@media (min-width: 1400px) {
  .main-template .container {
    max-width: 85%;
  }
}

.purple-line {
  height: 5px;
  width: 50%;
  background: linear-gradient(to right, $primary, rgba(128, 0, 128, 0));
  text-align: center;
  z-index: 1;
}


.container {
  .primary-title {
    font-weight: $font-weight-bold;
    font-family: $font-regular;
    font-size: clamp(2.650rem, 4vw, 3.850rem);
    line-height: clamp(2.650rem, 4vw, 3.850rem);
    letter-spacing: 0px;
    color: $primary;

  }

  .secondary-title {
    font-weight: $font-weight-bold;
    font-family: $font-regular;
    font-size: clamp(2.650rem, 4vw, 3.850rem);
    line-height: clamp(2.650rem, 4vw, 3.850rem);
    letter-spacing: 0px;
    color: $secondary;

  }

  .primary-subtitle {
    font-weight: $font-weight-bold;
    font-family: $font-regular;
    font-size: clamp(1.250rem, 5vw, 2.250rem);
    line-height: clamp(1.250rem, 5vw, 2.250rem);
    letter-spacing: 0px;
    color: $primary;
    margin: 0;
  }

  .secondary-subtitle {
    font-weight: $font-weight-bold;
    font-family: $font-regular;
    font-size: clamp(1.250rem, 5vw, 2.250rem);
    line-height: clamp(1.250rem, 5vw, 2.250rem);
    letter-spacing: 0px;
    color: $secondary;
    margin: 0;
  }
}

.gap-10 {
  gap: 10px;
}

.gap-20 {
  gap: 20px;
}

.gap-30 {
  gap: 30px;
}

.grecaptcha-badge {
  visibility: hidden !important;
}

.error {
  color: red;
  font-size: 1.275rem;
}

@keyframes fadeDownUp {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.fade-down-up {
  animation: fadeDownUp 1.5s ease-in-out;
  animation-fill-mode: forwards;
}


@keyframes fade {
  0% {
    opacity: 0;
    transform: translate(0);
  }
  100% {
    opacity: 1;
    transform: translate(0);
  }
}

.fade {
  animation: fade 1.5s ease-in;
  animation-fill-mode: forwards;
}
