@import 'node_modules/bootstrap/scss/functions';

$font-family-base:                                          "Open Sans", sans-serif !default;
$font-regular:                                           "Archivo", sans-serif;
$font-italic:                                          "Archivo Italic", sans-serif;

$dark-text: #191919;
$light: #f8fcfc;
$light-100: #f3f3f3;
$light-gray: #e4eaec;
$black-800: #121111;
$black-700: #111111;
$black-600: #1e1e1e;
$black-400: #464646;
$lighter-light: #F0F0F0;
$red-light: #FF3B23;
$black-500: #484E53;
$orange-light: #FFE4CD;
$purple-light: #E4D3FF;
$green-light: #ACFFD3;
$cyan-light: #79D4DB;
$gray-50: #E9ECEF;
$gray-150: #ADB5BD;
$blue-50: #E6F0FF;
$indigo-50: #e8ddfd;
$purple-50: #e8ddff;
$pink-50: #ffddee;
$red-50: #ffe1e3;
$yellow-50: #faf0cd;
$teal-50: #dbfff5;
$orange-50: #ffead7;
$green-50: #ddf4e2;
$cyan-50: #dafbff;
$orange-150: #FFEAD8;
$green-80: #D3FFE7;
$purple-80: #EFE5FF;
$orange-80: #FFEDDE;
/* Muse Font Weight Variables */
$font-weight-semibold: 600;
$font-weight-bold: 700;
$font-weight-extrabold: 800;
$font-weight-medium: 500;
$font-weight-light: 300;
/* Muse Transition Variables */
$transition4: all ease .4s;
$white: #ffffff;
$primary: #6633FF;
$secondary: #A8F97D;
$spacer: 3rem;

$container-max-widths: (
  xs: 90%,
  sm: 90%,
  md: 85%,
  lg: 85%,
  xl: 85%,
  xxl: 85%
);


@import 'node_modules/bootstrap/scss/bootstrap';
